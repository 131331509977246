import { useState, useEffect } from "react";

export default function useSeleccionServicio() {
  const [items, setItems] = useState(localStorage);
  const [spinner, setSpinner] = useState(false);
  const [servicio, setServicio] = useState([]);
  const [servicioSeleccionado, setServicioSeleccionado] = useState(null);
  const [kilometrajes, setKilometrajes] = useState([]);
  const [ready, setReady] = useState(false);

  //console.log("🚀 ~ servicios", servicios);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items) {
      setItems([items]);
    }
  }, []);

  let km = servicioSeleccionado;
  let modelo = items[0]?.modelo;
  let motor = items[0]?.motor;

  useEffect(() => {
    let kmURI = encodeURIComponent(km);
    let modeloURI = encodeURIComponent(modelo);
    let motorURI = encodeURIComponent(motor);

    setSpinner(true);
    var url = `https://store.lenken.com.ar/lenkenapi/servicios/seleccionServicio/km/${kmURI}/modelo/${modeloURI}/motor/${motorURI}`;

    if (servicioSeleccionado !== null) {
      fetch(url)
        .then((data) => {
          console.log(url, "url");
          return data.json();
        })
        .then((modelos) => {
          setServicio(modelos.data);
          setSpinner(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicioSeleccionado]);

  useEffect(() => {
    let modeloURI = encodeURIComponent(modelo);
    let motorURI = encodeURIComponent(motor);

    let url = `https://store.lenken.com.ar/lenkenapi/servicios/serviciosDisponibles/modelo/${modeloURI}/motor/${motorURI}`;

    fetch(url)
      .then((data) => {
        //console.log(url, "url");
        return data.json();
      })
      .then((modelo) => {
        //console.log(modelo.data, "modelos.data");
        setKilometrajes(modelo.data);
        setSpinner(false);
        setReady(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelo, ready]);

  const onChange = (e) => {
    setServicioSeleccionado(e.target.value);
  };

  const handleSeleccionSucursal = (sucursal) => {
    let obj = items[0];
    obj.sucursal = sucursal;
    localStorage.setItem("items", JSON.stringify(obj));
    //console.log(items[0], "servicioSeleccionado");
  };

  const handleButtonClick = (servicio) => {
    servicio.origen = "servicios";
    localStorage.setItem("items", JSON.stringify(servicio));
    /* localStorage.clear(); */
  };

  const handleSeleccionTurno = (day, time) => {
    let obj = items[0];
    obj.turno = day + " " + time;
    localStorage.setItem("items", JSON.stringify(obj));
    //console.log(obj);
  };

  return {
    items,
    servicio,
    spinner,
    kilometrajes,
    onChange,
    servicioSeleccionado,
    handleSeleccionSucursal,
    handleButtonClick,
    handleSeleccionTurno,
  };
}
