import React from "react";
import { Col, Row } from "react-bootstrap";
import useHome from "../hooks/useHome";

import A1 from "../../../assets/images/miniaturas/a1.png";
import A1Side from "../../../assets/images/miniaturas/a1-side.png";
import A3 from "../../../assets/images/miniaturas/a3.png";
import A3Side from "../../../assets/images/miniaturas/a3-side.png";
import A4 from "../../../assets/images/miniaturas/a4.png";
import A4Side from "../../../assets/images/miniaturas/a4-side.png";
import A5 from "../../../assets/images/miniaturas/a5.png";
import A5Side from "../../../assets/images/miniaturas/a5-side.png";
import A6 from "../../../assets/images/miniaturas/a6.png";
import A6Side from "../../../assets/images/miniaturas/a6-side.png";
import A7 from "../../../assets/images/miniaturas/a7.png";
import A7Side from "../../../assets/images/miniaturas/a7-side.png";
import ETron from "../../../assets/images/miniaturas/e-tron.png";
import ETronSide from "../../../assets/images/miniaturas/e-tron-side.png";
import Q2 from "../../../assets/images/miniaturas/q2.png";
import Q2Side from "../../../assets/images/miniaturas/q2-side.png";
import Q3 from "../../../assets/images/miniaturas/q3.png";
import Q3Side from "../../../assets/images/miniaturas/q3-side.png";
import Q5 from "../../../assets/images/miniaturas/q5.png";
import Q5Side from "../../../assets/images/miniaturas/q5-side.png";
import Q7 from "../../../assets/images/miniaturas/q7.png";
import Q7Side from "../../../assets/images/miniaturas/q7-side.png";
import Q8 from "../../../assets/images/miniaturas/q8.png";
import Q8Side from "../../../assets/images/miniaturas/q8-side.png";
import Q8ETron from "../../../assets/images/miniaturas/q8-e-tron.png";
import Q8ETronSide from "../../../assets/images/miniaturas/q8-e-tron-side.png";
import ETronGT from "../../../assets/images/miniaturas/e-tron-gt.png";
import ETronGTSide from "../../../assets/images/miniaturas/e-tron-gt-side.png";
import R8 from "../../../assets/images/miniaturas/r8.png";
import R8Side from "../../../assets/images/miniaturas/r8-side.png";
import S3 from "../../../assets/images/miniaturas/s3.png";
import S3Side from "../../../assets/images/miniaturas/s3-side.png";

export default function TarjetaSlider({ vehiculo }) {
  const { alterImg, primaryImg, handleLocation } = useHome();

  const handleSrc = (vehiculo) => {
    switch (vehiculo) {
      case "A1":
        return A1;
      case "A3":
        return A3;
      case "A4":
        return A4;
      case "A5":
        return A5;
      case "A6":
        return A6;
      case "A7":
        return A7;
      case "e-tron":
        return ETron;
      case "Q2":
        return Q2;
      case "Q3":
        return Q3;
      case "Q5":
        return Q5;
      case "Q7":
        return Q7;
      case "Q8":
        return Q8;
      case "Q8 E-Tron":
        return Q8ETron;
      case "E-Tron GT":
        return ETronGT;
      case "R8":
        return R8;
      case "S3":
        return S3;
      case "A1Side":
        return A1Side;
      case "A3Side":
        return A3Side;
      case "A4Side":
        return A4Side;
      case "A5Side":
        return A5Side;
      case "A6Side":
        return A6Side;
      case "A7Side":
        return A7Side;
      case "e-tronSide":
        return ETronSide;
      case "Q2Side":
        return Q2Side;
      case "Q3Side":
        return Q3Side;
      case "Q5Side":
        return Q5Side;
      case "Q7Side":
        return Q7Side;
      case "Q8Side":
        return Q8Side;
      case "Q8 E-TronSide":
        return Q8ETronSide;
      case "E-Tron GTSide":
        return ETronGTSide;
      case "R8Side":
        return R8Side;
      case "S3Side":
        return S3Side;

      default:
    }
  };

  return (
    <Row className="m-0 p-0 text-center" style={{ cursor: "pointer" }}>
      <Col xs={12} className="m-0 p-0 ">
        <img
          src={handleSrc(vehiculo)}
          data-primarySrc={handleSrc(vehiculo)}
          data-alterSrc={handleSrc(vehiculo + "Side")}
          alt="auto lateral"
          onMouseOver={(e) => alterImg(e)}
          onMouseOut={(e) => primaryImg(e)}
          width={"90%"}
          onClick={(e) => {
            alterImg(e);
            handleLocation();
          }}
        />
      </Col>
      <Col xs={12} style={{ color: "black" }}>
        audi {vehiculo}
      </Col>
    </Row>
  );
}
