import React, { useContext } from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uiContext } from "../../contexts/UiContextProvider";

import { Row, Col } from "react-bootstrap/";

import { ShowAt } from "react-with-breakpoints";

import Image from "react-bootstrap/Image";
import logoLenken from "../../assets/images/logo.png";
import logoAudi from "../../assets/images/marca.png";

import { MARCAS } from "../../constants/constants";

export default function NavigationBar() {
  const uiManager = useContext(uiContext);

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
  };

  return (
    <Navbar
      /* fixed="top" */
      className="shadow-sm text-center p-0"
      style={{ height: "60px", backgroundColor: "white" }}
    >
      <Navbar.Brand>
        <Link to="/" className="styled-nav">
          <Col className="pl-0">
            <Image width="140px" src={logoLenken} />
          </Col>
        </Link>
      </Navbar.Brand>

      <ShowAt breakpoint="largeAndAbove">
        <Row
          className="m-0 p-0 pt-2 justify-content-center font-weight-bold"
          style={{ fontSize: "15px" }}
        >
          <Col>
            <a
              href="https://store.lenken.com.ar/vtf/"
              className="styled-nav"
              style={{ whiteSpace: "nowrap" }}
            >
              modelos nuevos
            </a>
          </Col>
          <Link
            to={{
              pathname: "/usados/catalogo",
              state: { product: { marca: MARCAS.OTRAS } },
            }}
            onClick={() => handleButtonClick({ marca: MARCAS.OTRAS })}
            className="styled-nav"
          >
            <Col> usados</Col>
          </Link>

          {/*  <Col>
            <a
              href="https://store.lenken.com.ar/cotizador/"
              className="styled-nav"
              style={{ whiteSpace: "nowrap" }}
            >
              cotizá tu usado
            </a>
          </Col>
 */}
          <Link to="/servicios" className="styled-nav">
            <Col> servicios</Col>
          </Link>
          <Col>
            <Link to="/accesorios" className="styled-nav">
              accesorios
            </Link>
          </Col>
          <Col>
            <Link to="/repuestos" className="styled-nav">
              repuestos
            </Link>
          </Col>
        </Row>

        <Nav className="mr-auto"></Nav>
      </ShowAt>
      <Nav className="mr-auto"></Nav>
      <Navbar.Brand className="mr-0">
        <a href="https://www.audi.com.ar" target="_blank" rel="noreferrer">
          <Col className="pr-0">
            <Image width="100px" src={logoAudi} />
          </Col>
        </a>
      </Navbar.Brand>
      <Nav>
        {/* <Navbar.Brand
          style={{ fontSize: "17px", paddingTop: "10px", marginRight: "5px" }}
        >
          menu
        </Navbar.Brand> */}
        <Button
          className="d-block d-md-none without-focus-border"
          variant="transparent"
          onClick={uiManager.sideBar.show}
          style={{ paddingTop: "10px" }}
        >
          <FontAwesomeIcon icon="bars" style={{ color: "black" }} />
        </Button>
        <Button
          className="d-none d-md-block btn-outline-light"
          variant="transparent"
          onClick={uiManager.sideBar.toggleCollapsed}
          style={{ paddingTop: "10px" }}
        >
          <FontAwesomeIcon icon="bars" style={{ color: "black" }} />
        </Button>
        <ShowAt breakpoint="mediumAndAbove"></ShowAt>
      </Nav>
    </Navbar>
  );
}
