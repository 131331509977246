export const socialMedia = {
  facebook: "https://www.facebook.com/audilenkendevoto/",
  instagram: "https://www.instagram.com/audilenken/",
  youtube: "https://www.youtube.com/@audilenkendevoto/videos",
  linkedin: "https://ar.linkedin.com/company/auto-lenken",
  tiktok: "https://www.tiktok.com/@audilenken",
};

export const imgPath = "https://store.lenken.com.ar/img/imgDefault/";
export const imagPathUsados = "https://store.lenken.com.ar/img/";

export const MARCAS = {
  TOYOTA: "",
  TOYOTA_CERTIFICADO: "",
  OTRAS: "OTRAS MARCAS",
};

export const capitalize = (text) =>
  text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

export const truncate = (text, length) =>
  text && text.length > length ? `${text.substring(0, length)}` : text;
