import { useState, useEffect } from "react";
import { Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import { imgPath } from "../../../constants/constants";

export default function useServicios() {
  const [modelos, setModelos] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    setSpinner(true);
    var url = "https://store.lenken.com.ar/lenkenapi/servicios";

    fetch(url)
      .then((data) => {
        return data.json();
      })
      .then((modelos) => {
        setModelos(modelos.data);
        setSpinner(false);
      });
  }, []);

  useEffect(() => {
    setLoading(Array(modelos.length).fill(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelos]);

  //console.log(loading);

  const getModelos = () => {
    return modelos.map((modelo, i) => {
      return (
        <Col
          xs={12}
          md={3}
          className="my-3 text-center"
          key={i}
          onClick={() => handleButtonClick(modelo)}
        >
          <Col
            style={{ display: loading[i] ? "block" : "none" }}
            className="text-center mt-5"
          >
            <Spinner animation="grow" variant="secondary" />
          </Col>
          <Link
            to={{
              pathname: "/servicios/seleccionServicio",
              state: { modeloSeleccionado: { ...modelo, origen: "servicios" } },
            }}
            style={{
              color: "black",
              cursor: "pointer",
              display: loading[i] ? "none" : "block",
            }}
          >
            <img
              src={imgPath + modelo.foto}
              alt={modelo.modelo}
              style={{ objectFit: "contain", width: "100%", height: "200px" }}
              onLoad={() =>
                setLoading((loading) =>
                  loading.map((item, j) => (j === i ? false : item))
                )
              }
            />
            <h3 className="text-center">
              <b>{modelo.modelo}</b>
            </h3>
          </Link>
        </Col>
      );
    });
  };

  const handleButtonClick = (modelo) => {
    modelo.origen = "servicios";
    localStorage.setItem("items", JSON.stringify(modelo));
    /* localStorage.clear(); */
  };

  return {
    modelos,
    spinner,
    imgPath,
    handleButtonClick,
    getModelos,
  };
}
