import React, { useState, useEffect } from "react";
import ItemsCarousel from "react-items-carousel";
import TarjetaSlider from "./TarjetaSlider.js";
import { Col, Row } from "react-bootstrap";

export default function Slider({ modelos }) {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;

  const [firstAndLastGutter, setFirstAndLastGutter] = useState(false);
  const [cantTarjetas, setCantTarjetas] = useState(3);

  const size = useWindowSize();

  function useWindowSize() {
    const isClient = typeof window === "object";

    function getSize() {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
      };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
      if (!isClient) {
        return false;
      }

      function handleResize() {
        setWindowSize(getSize());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return windowSize;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (size.width < 450) {
      setCantTarjetas(1);
      setFirstAndLastGutter(false);
    } else {
      if (size.width < 700) {
        setCantTarjetas(2);
        setFirstAndLastGutter(true);
      } else {
        if (size.width < 800) {
          setCantTarjetas(2.7);
          setFirstAndLastGutter(true);
        } else {
          if (size.width < 1000) {
            setCantTarjetas(3.7);
            setFirstAndLastGutter(true);
          } else {
            setCantTarjetas(4.7);
            setFirstAndLastGutter(true);
          }
        }
      }
    }
  });

  const sliderModelo = () => {
    var links = modelos.map((vehiculo, i) => {
      return <TarjetaSlider key={i} vehiculo={vehiculo} />;
    });
    return links;
  };

  return (
    <Row
      className="m-0 p-0 mx-5"
      onClick={() => window.dataLayer.push({ event: "vehículos nuevos" })}
    >
      <Col
        xs={12}
        style={{
          borderRight: "1px solid lightGray",
          /* borderLeft: "1px solid lightGray", */
        }}
        className="m-0 p-0"
      >
        <ItemsCarousel
          infiniteLoop={false}
          activePosition={"center"}
          disableSwipe={false}
          alwaysShowChevrons={false}
          slidesToScroll={1}
          showSlither={false}
          firstAndLastGutter={firstAndLastGutter}
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={cantTarjetas}
          gutter={0}
          leftChevron="<"
          rightChevron=">"
          outsideChevron={true}
          chevronWidth={chevronWidth}
        >
          {sliderModelo()}
        </ItemsCarousel>
      </Col>
    </Row>
  );
}
