import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

export default function Sucursales() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Row className="m-0 p-0 my-5">
      <Col xs={12} lg={{ span: "8", offset: "2" }}>
        <Row className="m-0 p-0">
          <a
            href="https://www.youtube.com/watch?v=XTm7QDe_hM0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://store.lenken.com.ar/img/imgSitio/turorialSucursal.png"
              alt="video sucursal"
              style={{ borderRadius: "5px" }}
            />
          </a>
        </Row>
        <Row
          className="m-0 p-0  mt-3"
          style={{ borderRadius: "5px", backgroundColor: "lightGray" }}
        >
          <Col className="pt-2">
            <h3>Sucursales</h3>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>Administracion y Ventas</h3>
            <br /> Av. Fco. Beiro 3355 (C1419HZG) Ciudad de Buenos Aires,
            <br />
            Argentina 4505-8666
            <br /> info@lenken.com.ar
            <br />
            <b>Administración</b>
            <br />
            Lunes a Viernes 8:30 a.18.00hs
            <br />
            <b>Ventas</b>
            <br />
            Lunes a Viernes 9 a 19hs Domingos y Feriados Cerrado
            <br /> Sábado de 9.30 a 14.30hs
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.214180983024!2d-58.503120823577305!3d-34.59874517295618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb63103b2398b%3A0xebe9f7a55116c7aa!2sAudi%20Lenken!5e0!3m2!1sen!2sar!4v1689796434406!5m2!1sen!2sar"
              width="100%"
              height="200"
              style={{ border: "0" }}
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              title="Av. Francisco Beiró 3355, Cdad. Autónoma de Buenos Aires"
            />
          </Col>
        </Row>
        <hr />

        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>Servicio Audi</h3>
            <br /> Llavallol 3555 (C1419AJA) Ciudad de Buenos Aires,
            <br />
            Argentina 4505-8666
            <br /> info@lenken.com.ar
            <br />
            <b>Servicio</b>
            <br />
            Lunes a Viernes de 8 a 13 y 14 a 18hs
            <br />
            Sábado y Domingo Cerrado
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13136.862097202416!2d-58.5011244!3d-34.5987112!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb63111f18075%3A0x7565a71a50f00ded!2sPost%20leken%20Auto%20Sales!5e0!3m2!1sen!2sar!4v1689796959451!5m2!1sen!2sar"
              width="100%"
              height="200"
              style={{ border: "0" }}
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              title="Llavallol 3555, C1419AJA CABA"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
