import { useEffect, useState } from "react";

export default function useHome() {
  const [modelos, setModelos] = useState([]);

  useEffect(() => {
    fetch("https://store.lenken.com.ar/lenkenapi/autos/modelos")
      .then((data) => {
        return data.json();
      })
      .then((modelos) => {
        function order(a, b) {
          return a < b ? -1 : a > b ? 1 : 0;
        }
        var sortModels = modelos.data.map(({ modelo }) => modelo).sort(order);

        setModelos(sortModels);
      });
  }, []);

  const alterImg = (event) => {
    event.target.style.opacity = "0.1";
    event.target.style.transition = "opacity 0.2s ease-in-out";
    setTimeout(function () {
      event.target.src = event.target.dataset.altersrc;
      event.target.style.opacity = "1";
    }, 200);
  };
  const primaryImg = (event) => {
    event.target.src = event.target.dataset.primarysrc;
  };

  const handleLocation = () => {
    setTimeout(function () {
      window.location.href = "https://store.lenken.com.ar/vtf/";
    }, 400);
  };

  return { modelos, alterImg, primaryImg, handleLocation };
}
