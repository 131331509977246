import { useContext, useEffect, useState } from "react";

import { Button, Col, Pagination, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import GenericCard from "../../common/GenericCard";
import WhatsappContactButton from "../../common/WhatsappContactButton";

import { capitalize } from "../../../constants/constants";

import { repContext } from "../context/RepuestosContext";

export default function useRepuestos() {
  let history = useHistory();

  const {
    paramId,
    emptySearch,
    setEmptySearch,
    repuestos,
    POSTPERPAGE,
    setSeleccion,
    seleccion,
    handleChangeFilter,
    spinner,
    handleSearch,
    search,
    handlePagination,
    currentPage,
    totalPages,
    PAGESONSCREEN,
    totalItems,
    handlePrev,
    handleNext,
  } = useContext(repContext);

  const [botones, setBotones] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [items, setItems] = useState(localStorage);

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
    /* localStorage.clear(); */
  };

  useEffect(() => {
    var url = `https://store.lenken.com.ar/lenkenapi/repuestos/modelos/`;

    fetch(url)
      .then((data) => {
        return data.json();
      })
      .then((modelos) => {
        setBotones(modelos.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonsToRender = () => {
    //como hacer distinct de dos formas distitas
    /*  const uniqueTags = [];
    accesorios.forEach((accesorio) => {
      if (uniqueTags.indexOf(accesorio.modelo) === -1) {
        uniqueTags.push(accesorio.modelo);
      }
    }); */
    /* const uniqueTags = Array.from(
      new Set(accesorios.map(({ modelo }) => modelo))
    ); */

    return (
      <>
        <Button
          size="sm"
          variant="light"
          className="mx-1 align-text-top my-1"
          onClick={() => {
            if (paramId !== null) {
              history.push("/repuestos");
              setEmptySearch(!emptySearch);
            }
            setSeleccion("combos");
          }}
        >
          Ofertas
        </Button>

        {botones &&
          botones.map((item, i) => (
            <Button
              size="sm"
              variant="light"
              className="mx-1 align-text-top my-1"
              key={"id" + i}
              onClick={() => {
                if (paramId !== null) {
                  history.push("/repuestos");
                  setEmptySearch(!emptySearch);
                }
                setSeleccion(item.modelo);
              }}
            >
              {item.modelo === "TTRS" ||
              item.modelo === "TT" ||
              item.modelo === "RS4" ||
              item.modelo === "RS3" ||
              item.modelo === "RS5" ||
              item.modelo === "RS7" ||
              item.modelo === "AUDI"
                ? item.modelo
                : capitalize(item.modelo.toLowerCase())}
            </Button>
          ))}
      </>
    );
  };

  const genericCardstoRender = () => {
    return repuestos.length > 0 ? (
      <>
        {repuestos.map(
          (repuesto, i) =>
            i <= POSTPERPAGE && (
              <Col sm={5} md={5} lg={4} xl={3} className="m-1" key={"id" + i}>
                <GenericCard
                  title={repuesto.modelo}
                  text={repuesto.anio}
                  image={repuesto.foto0}
                  imageFixedPath="https://store.lenken.com.ar/img/"
                  price={Intl.NumberFormat("es-AR", {
                    currency: "ARS",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(repuesto.precio)}
                  buttonBackgroundColor="black"
                  action="comprar"
                  to="/tarjetas"
                  product={{
                    id: repuesto.id,
                    modelo: repuesto.modelo,
                    motor: repuesto.motor,
                    foto0: repuesto.foto0,
                    foto1: repuesto.foto1,
                    foto2: repuesto.foto2,
                    foto3: repuesto.foto3,
                    foto4: repuesto.foto4,
                    foto5: repuesto.foto5,
                    foto6: repuesto.foto6,
                    foto7: repuesto.foto7,
                    foto8: repuesto.foto8,
                    foto9: repuesto.foto9,
                    foto10: repuesto.foto10,
                    foto11: repuesto.foto11,
                    precio: repuesto.precio,
                    moneda: "$",
                    origen: "postventa",
                  }}
                  handleButtonClick={handleButtonClick}
                  //--- atributos de autos usados
                  from="repuestos"
                  link="/repuestos/repuestosFotos"
                  whatsappButton={
                    <WhatsappContactButton
                      origen="repuestos"
                      modelo={repuesto}
                    />
                  }
                  compatible={repuesto.descripcion}
                />
              </Col>
            )
        )}
      </>
    ) : (
      <Col className="text-center my-4" xs={12} md={{ span: 4 }}>
        <h4>No hay productos disponibles para esta selección</h4>
      </Col>
    );
  };

  const getPagination = () => {
    return (
      totalItems > POSTPERPAGE && (
        <Row className="m-0 p-0 mb-4">
          <Col>
            <Pagination className="justify-content-center">
              <Pagination.First onClick={() => handlePagination(1)} />
              <Pagination.Prev onClick={() => handlePrev()} />

              {[...Array(totalPages)].map(
                (_, i) =>
                  currentPage - PAGESONSCREEN < i + 1 &&
                  currentPage + PAGESONSCREEN > i + 1 && (
                    <Pagination.Item
                      key={i}
                      active={i + 1 === currentPage && true}
                      onClick={() => handlePagination(i + 1)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  )
              )}

              <Pagination.Next onClick={() => handleNext()} />
              <Pagination.Last onClick={() => handlePagination(totalPages)} />
            </Pagination>
          </Col>
        </Row>
      )
    );
  };

  return {
    spinner,
    genericCardstoRender,
    buttonsToRender,
    handleChangeFilter,
    search,
    getPagination,
    seleccion,
    handleSearch,
    items,
  };
}
