import { useEffect, useState } from "react";

export default function useApp() {
  const [loaded, setLoaded] = useState(false);

  let url = window.location.href;

  useEffect(() => {
    if (url.includes("?idInterno=")) {
      fetch("https://store.lenken.com.ar/lenkenapi/autos/usados")
        .then((data) => {
          return data.json();
        })
        .then((modelos) => {
          console.log(modelos);
          localStorage.setItem("modelos", JSON.stringify(modelos));
          setLoaded(true);

          //console.log("cargue el listado");
        });
    } else {
      setLoaded(true);
      //console.log("no cargue el listado");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loaded,
  };
}
