import React, { useEffect } from "react";
import { /* Card, */ Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import usados from "../../assets/images/secciones/usados.jpg";
import usadosMobile from "../../assets/images/secciones/usadosMobile.jpg";

import accesoriosVideo from "../../assets/videos/accesorios.mp4";

import servicios from "../../assets/images/secciones/servicios.jpg";
import repuestos from "../../assets/images/secciones/repuestos.jpg";
import ventas from "../../assets/images/secciones/ventas.jpg";
import instalaciones from "../../assets/images/secciones/instalaciones.jpg";
import valet from "../../assets/images/secciones/valet.jpg";

//import ahora3 from "../../assets/images/logos/ahora3.jpg";

import useHome from "./hooks/useHome";

import Carousel from "./Carousel";
import Slider from "./slider/Slider";
import { MARCAS } from "../../constants/constants";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
  };

  const { modelos } = useHome();

  return (
    <>
      <Carousel />
      <Row className="m-0 p-0">
        <Col
          style={{
            backgroundColor: "#333333",
            color: "white",
            height: "10vh",
            fontSize: "1.2rem",
            fontFamily: "Audi Extended",
          }}
          className="d-flex align-items-center"
        >
          modelos nuevos disponibles con entrega inmediata
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5">
        <Col
          style={{
            backgroundColor: "#666666",
            color: "white",
            height: "4vh",
            fontSize: "0.8rem",
            fontFamily: "Audi Normal",
          }}
          className="d-flex align-items-center"
        >
          ingrese a cada modelo para ver las versiones disponibles
        </Col>
      </Row>
      <Slider modelos={modelos} />

      <Row className="m-0 p-0 mt-5">
        <Col xs={12} className="m-0 p-0">
          <Link
            to={{
              pathname: "/usados/catalogo",
              state: { product: { marca: MARCAS.OTRAS } },
            }}
            onClick={() => handleButtonClick({ marca: MARCAS.OTRAS })}
          >
            <p className="textoUsados text-left">
              Vehículos usados disponibles de inmediato
            </p>
            <div className="boxUsados">buscar ahora</div>
            <picture>
              <source media="(min-width:600px)" srcset={usados} />
              <img src={usadosMobile} alt="usados" style={{ width: "100%" }} />
            </picture>
          </Link>
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5 text-center">
        <Col>
          <h1 style={{ fontFamily: "Audi Extended" }}>
            En tres pasos hasta el vehículo de sus sueños
          </h1>
        </Col>
      </Row>
      <Row className="m-0 p-0 text-center">
        <Col xs={12} lg={4} className="borderRightOrderProcess mb-5 pb-5">
          <p className="pb-4">
            <b>encuentra el vehículo de tus sueños</b>
          </p>
          Utilice nuestra búsqueda de vehículos para seleccionar el vehículo
          deseado de todos los vehículos nuevos y usados ​​actualmente
          disponibles.
        </Col>
        <Col xs={12} lg={4} className="borderRightOrderProcess mb-5 pb-5">
          <p className="pb-4">
            <b>elija el paquete de gastos</b>
          </p>
          Decida durante el proceso de pedido como desea registrar el vehículo
          de sus sueños.
        </Col>
        <Col xs={12} lg={4} className="pb-5">
          <p className="pb-4">
            <b>orden completa</b>
          </p>
          Complete el proceso con solo unos pocos clics. Diviértete con el nuevo
          vehículo de tus sueños.
        </Col>
      </Row>
      <Row className="m-0 p-0 mt-5">
        <Col xs={12} className="m-0 p-0">
          <Link to="/accesorios">
            <p className="textoAccesorios text-left">Accesorios exclusivos</p>
            <div className="boxUsados">ver accesorios ahora</div>
            <video
              controls={false}
              autoPlay
              muted
              loop
              style={{ objectFit: "cover" }}
              src={accesoriosVideo}
              type="video/mp4"
              className="m-0 p-0"
              width={"100%"}
            >
              Tu navegador no soporta videos.
            </video>
          </Link>
        </Col>
      </Row>
      {/*  <Row className="mx-0 mt-3 mb-5 p-0">
        <Col xs={12} lg={{ span: "6", offset: "3" }}>
          <Link to="/accesorios" style={{ color: "#212529" }}>
            <Card>
              <Card.Body style={{ fontSize: "18px" }} className="text-center">
                <FontAwesomeIcon
                  icon="info-circle"
                  style={{ color: "black" }}
                />{" "}
                accesorios originales con la mejor financiación
                <img src={ahora3} alt="ahora3 logo" width="35px" />
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row> */}
      <Row className="m-0 p-0 mt-5">
        <Col xs={12} className="my-5">
          <h1 style={{ fontFamily: "Audi Extended" }} className="text-center">
            Mantenga su Audi funcionando como un Audi.
          </h1>
        </Col>
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="text-center">
          El servicio de calidad comienza con nuestros técnicos Audi capacitados
          en la fábrica que utilizan los últimos equipos de diagnóstico y piezas
          originales de Audi para mantener su vehículo en sintonía. Obtenga más
          información sobre el programa de mantenimiento, las garantías y el
          manual del propietario a continuación. También encontrará las muchas
          formas en que estamos aquí para usted, incluida la asistencia en la
          carretera y las citas de servicio remoto.
        </Col>
      </Row>
      <Row className="m-0 p-0 mt-5">
        <Col xs={12} className="m-0 p-0">
          <Link to="/servicios">
            <p className="textoServicios text-left">Servicios</p>
            <div className="boxUsados">reservar ahora</div>
            <picture>
              <img
                src={servicios}
                alt="servicios"
                style={{ width: "100%", height: "50vh", objectFit: "cover" }}
              />
            </picture>
          </Link>
        </Col>
      </Row>

      <Row className="m-0 p-0 mt-5">
        <Col xs={12} className="my-5">
          <h1 style={{ fontFamily: "Audi Extended" }} className="text-center">
            Repuestos Originales Audi.
          </h1>
        </Col>
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="text-center">
          Cuando utiliza piezas originales de Audi, puede estar seguro de que
          todas las piezas encajarán y funcionarán como las originales de
          fábrica. Todas las piezas de Audi están diseñadas teniendo en cuenta
          especificaciones y directrices estrictas, y su durabilidad se somete a
          rigurosas pruebas. Además, las piezas de Audi están respaldadas por
          una garantía. 1 Mantenga su vehículo funcionando tan bien como el día
          que lo compró con piezas originales de Audi.
        </Col>
      </Row>
      <Row className="m-0 p-0 mt-5 mb-3">
        <Col xs={12} className="m-0 p-0">
          <Link to="/repuestos">
            <p className="textoServicios text-left">Repuestos</p>
            <div className="boxUsados">ver repuestos ahora</div>
            <picture>
              <img
                src={repuestos}
                alt="respuestos"
                style={{ width: "100%", height: "50vh", objectFit: "cover" }}
              />
            </picture>
          </Link>
        </Col>
      </Row>
      {/*  <Row className="mx-0 mt-3 mb-5 p-0">
        <Col xs={12} lg={{ span: "6", offset: "3" }}>
          <Link to="/repuestos" style={{ color: "#212529" }}>
            <Card>
              <Card.Body style={{ fontSize: "18px" }} className="text-center">
                <FontAwesomeIcon
                  icon="info-circle"
                  style={{ color: "black" }}
                />{" "}
                repuestos originales con la mejor financiación
                <img src={ahora3} alt="ahora3 logo" width="35px" />
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row> */}
      <Row className="m-5 p-0 my-5">
        <Col xs={12} lg={4} className="p-1 my-4">
          <img src={ventas} alt="ventas" width="100%" />
          <p className="pt-4">
            <b>asesoramiento especial</b>
          </p>
          <p>Descubra una atención diferencial, especializada.</p>
          <br />
          <a
            href={`https://wa.me/5491145058666?text=Hola, estoy interesado en el modelo. Mi consulta es:%20`}
            style={{ color: "black" }}
          >
            <p>obtener informacion {">"}</p>
          </a>
        </Col>
        <Col xs={12} lg={4} className="p-1 my-4">
          <img src={instalaciones} alt="instalaciones" width="100%" />
          <p className="pt-4">
            <b>espacios únicos </b>
          </p>
          <p>
            Encuentre las instalaciones mas agradables durante la inspección de
            su vehículo
          </p>
          <a
            href={`https://wa.me/5491145058666?text=Hola, quiero reservar el servicio para mi Audi:%20`}
            style={{ color: "black" }}
          >
            <p>solicitar servicio {">"}</p>
          </a>
        </Col>
        <Col xs={12} lg={4} className="p-1 my-4">
          <img src={valet} alt="valet" width="100%" />
          <p className="pt-4">
            <b>servicio de valet</b>
          </p>
          <p>
            Servicio de mantenimiento puerta a puerta. Retiramos su vehículo,
            realizamos el mantenimiento y lo entregamos nuevamente en su
            domicilio.
          </p>
          <a
            href={`https://wa.me/5491145058666?text=Hola, estoy interesado en el servicio de valet:%20`}
            style={{ color: "black" }}
          >
            <p>obtener informacion {">"}</p>
          </a>
        </Col>
      </Row>
    </>
  );
}
