import React, { useEffect } from "react";

import useUsados from "./hooks/useUsados";
import { Row, Col, Spinner, Image, Form } from "react-bootstrap/";

import { /* capitalize, */ MARCAS } from "../../constants/constants";

import usadosSeccion from "../../assets/images/secciones/usadosSeccion.jpg";
import usadosSeccionMobile from "../../assets/images/secciones/usadosSeccionMobile.jpg";

export default function Catalogo() {
  const {
    spinner,
    genericCardstoRender,
    buttonsToRender,
    marcaSeleccionada,
    handleChangeFilter,
    search,
  } = useUsados();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.dataLayer.push({ event: "usados" });
  }, []);

  //console.log(product, "product desde catalogo");
  //console.log(items[0], "items desde catalogo");

  return (
    <>
      <Row className="m-0 p-0">
        <Col xs={12} className="m-0 p-0">
          <p className="textoUsados text-left">Vehículos usados</p>
          <picture>
            <source media="(min-width:600px)" srcset={usadosSeccion} />
            <img
              src={usadosSeccionMobile}
              alt="usados"
              style={{ width: "100%" }}
            />
          </picture>
        </Col>
      </Row>
      <Row className="m-0 mt-3 mb-3 p-0 justify-content-center">
        {marcaSeleccionada === MARCAS.TOYOTA_CERTIFICADO ? (
          <Col className="text-left p-2" xs={12} md={{ span: 4 }}>
            <Image
              className="mb-4"
              style={{ width: "90%", borderRadius: "5px" }}
              src="https://store.autoprana.com.ar/img/imgSitio/logos/usadosSele.jpg" //esto no va, solo queda para cuando este audi approved
            ></Image>
          </Col>
        ) : (
          ""
        )}
        <Col
          className="text-center mb-1 d-flex justify-content-center align-items-center"
          lg={9}
          style={{
            backgroundColor: "#D9D9D9",
            fontSize: "2rem",
            color: "black",
            flexWrap: "wrap",
          }}
        >
          {marcaSeleccionada && buttonsToRender()}
        </Col>
        <Col
          className="text-left pl-4 mb-1"
          lg={9}
          style={{
            backgroundColor: "#000",
            fontSize: "2rem",
            color: "black",
          }}
        >
          <Row className="m-0 p-0" style={{ height: "40px" }}>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 4 }}
              className="text-center mt-1"
              style={{ color: "white" }}
            >
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="buscar"
                  value={search}
                  onChange={(e) => handleChangeFilter(e)}
                  style={{ borderRadius: "0px" }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col
          className="text-left p-1 pl-4"
          lg={9}
          style={{
            backgroundColor: "darkgray",
            fontSize: "1.5rem",
            color: "black",
          }}
        >
          &nbsp;
          {marcaSeleccionada &&
            (marcaSeleccionada === MARCAS.OTRAS
              ? /* marcaSeleccionada.toLowerCase() */ ""
              : marcaSeleccionada)}
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5 justify-content-center text-left">
        {!spinner ? (
          genericCardstoRender()
        ) : (
          <>
            <Row className="mx-0" style={{ marginTop: "2%" }}>
              <Col xs={12} className="text-center">
                Cargando...
              </Col>
              <Col
                xs={12}
                className="text-center mx-0"
                style={{ marginTop: "10px" }}
              >
                <Spinner animation="grow" variant="secondary" />
              </Col>
            </Row>
          </>
        )}
      </Row>
    </>
  );
}
