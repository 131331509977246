import React from "react"; //imr
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap/";

import SideBar from "./SideBar";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";
import Whatsapp from "../common/Whatsapp";

import { socialMedia } from "../../constants/constants";
import { DateTime } from "luxon";

export default function Layout({ children }) {
  return (
    <>
      <Container className="m-0 p-0" fluid>
        <Row className="m-0 p-0">
          <Col className="m-0 p-0">
            <NavigationBar />
            {children}
          </Col>
        </Row>
        <Footer
          brand="Lenken | e-commerce"
          /* brandText="Con mas de 50 años en la industria automotriz, damos un nuevo paso para acercarnos a nuestros clientes y brindar nuevos servicios." */
          copyright={`© ${DateTime.now().toFormat(
            "yyyy"
          )} Copyright Lenken S.A.`}
          branches="Sucursales"
          /* branchesText="Hace click para ver nuestros puntos de venta." */
          socialMedia={socialMedia}
        />
      </Container>
      <Whatsapp />
      <SideBar />
    </>
  );
}
