import React, { useEffect } from "react";

import useServicios from "./hooks/useServicios";

import { Row, Col } from "react-bootstrap";

export default function Servicios() {
  const { getModelos } = useServicios();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.dataLayer.push({ event: "servicios" });
  }, []);

  return (
    <>
      <Row className="m-0 p-0">
        <Col xs={12} md={{ span: 8, offset: 1 }} className="mt-5">
          <h3 style={{ fontFamily: "Audi Extended" }}>
            Selecciona el modelo y año de tu vehículo
          </h3>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5">
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <Row className="m-0 p-0">{getModelos()}</Row>
        </Col>
      </Row>
    </>
  );
}
