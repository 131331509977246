import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";

import carousel_1 from "../../../assets/images/carousel/1.jpg";
import carousel_2 from "../../../assets/images/carousel/2.jpg";
import carousel_3 from "../../../assets/images/carousel/3.jpg";
import carousel_4 from "../../../assets/images/carousel/4.jpg";
export default function Index() {
  return (
    <Row className="m-0 p-0 shadow">
      <Col className="p-0">
        <Carousel fade className="carouselHeight">
          <Carousel.Item>
            <img
              className="d-block w-100 carouselItemHeight"
              src={carousel_1}
              alt="First slide"
            />
            <Carousel.Caption
              className="carouselCaption text-left"
              style={{
                background: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <p>Décadas en cada detalle</p>
            </Carousel.Caption>
            <Row className="m-0 p-0 carouselCaptionFooter">
              <Col className="carouselCaptionDescription text-center">
                Lenken una empresa especial dedica al servicio de sus clientes a
                la máxima expresión. Dejate llevar por algo exclusivo. Audi
                Lenken.
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100 carouselItemHeight"
              src={carousel_2}
              alt="First slide"
            />
            <Carousel.Caption
              className="carouselCaption text-left"
              style={{
                background: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <p>Mantente exclusivo</p>
            </Carousel.Caption>
            <Row className="m-0 p-0 carouselCaptionFooter">
              <Col className="carouselCaptionDescription text-center">
                Cualquiera que conduzca un Audi quiere algo especial. Audi
                Lenken le brinda la oportunidad de convertir algo especial en
                algo muy personal.
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100 carouselItemHeight"
              src={carousel_3}
              alt="First slide"
            />
            <Carousel.Caption
              className="carouselCaption text-left"
              style={{
                background: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <p>Accesorios para su Audi</p>
            </Carousel.Caption>
            <Row className="m-0 p-0 carouselCaptionFooter">
              <Col className="carouselCaptionDescription text-center">
                Descubra soluciones bien pensadas y hechas a medida que
                facilitan su día a día y lo inspiran a hacer nuevos planes.
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100 carouselItemHeight"
              src={carousel_4}
              alt="First slide"
            />
            <Carousel.Caption
              className="carouselCaption text-left"
              style={{
                background: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <p>Cuidamos cada detalle.</p>
            </Carousel.Caption>
            <Row className="m-0 p-0 carouselCaptionFooter">
              <Col className="carouselCaptionDescription text-center">
                Son las pequeñas cosas las que marcan la diferencia. Así que
                confíe en un socio que presta atención a cada detalle y conoce
                su Audi como ningún otro.
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
  );
}
