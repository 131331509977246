import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { Row, Col, Image } from "react-bootstrap/";

import { uiContext } from "../../contexts/UiContextProvider";

import audiMini from "../../assets/images/sideBar/audiMini.png";
import sportMini from "../../assets/images/sideBar/sportMini.png";

//Breakpoints
import { HideAt } from "react-with-breakpoints";
import { MARCAS } from "../../constants/constants";
import { NavLink } from "react-router-dom";

export default function SideBar() {
  const uiManager = useContext(uiContext);

  let height = window.innerHeight;
  let footerHeight = (32.8 * height) / 100;

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
  };

  return (
    <ProSidebar
      collapsed={uiManager.sideBar.collapsed}
      breakPoint={"md"}
      toggled={uiManager.sideBar.toggled}
      onToggle={uiManager.sideBar.hide}
      rtl={true}
    >
      <SidebarHeader
        style={{
          /* overflow: "hidden", */
          color: "black",
          borderLeft: "1px solid lightGrey",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Row className="m-0 p-0">
          <Col
            style={{ paddingTop: "20px", color: "black", height: "60px" }}
            onClick={uiManager.sideBar.hide}
          >
            <FontAwesomeIcon icon="times-circle" className="shake" />
          </Col>
        </Row>
      </SidebarHeader>

      <SidebarContent
        style={{
          borderLeft: "1px solid lightGrey",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Menu>
          <MenuItem>
            <a
              href="https://store.lenken.com.ar/vtf/"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              modelos nuevos
            </a>
          </MenuItem>
          <MenuItem>
            <NavLink
              to={{
                pathname: "/usados/catalogo",
                state: { product: { marca: MARCAS.OTRAS } },
              }}
              onClick={() => {
                handleButtonClick({ marca: MARCAS.OTRAS });
                uiManager.sideBar.hide();
              }}
              style={{ color: "black" }}
            >
              usados
            </NavLink>
          </MenuItem>
          {/*  <MenuItem>
            <a
              href="https://store.lenken.com.ar/cotizador/"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              cotizá tu usado
            </a>
          </MenuItem> */}

          <MenuItem>
            <NavLink
              to="/servicios"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              servicios
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/accesorios"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              accesorios
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/repuestos"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              repuestos
            </NavLink>
          </MenuItem>
          <hr></hr>

          <MenuItem icon={<Image src={audiMini} />}>
            <a
              href="https://www.audi.com.ar/ar/web/es/AudiLounge.html"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Mundo Audi
            </a>
          </MenuItem>
          <MenuItem icon={<Image src={sportMini} />}>
            <a
              href="https://www.audi.com.ar/ar/web/es/Innovacion2022/audi-sport.html"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Audi Sport
            </a>
          </MenuItem>
        </Menu>
        <HideAt breakpoint="mediumAndAbove"></HideAt>
      </SidebarContent>
      <SidebarFooter
        className="dynamicFooter"
        style={{ backgroundColor: "#f5f5f5", height: footerHeight }}
      ></SidebarFooter>
    </ProSidebar>
  );
}
